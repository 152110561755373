











































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
// import face from '@/components/face/face.vue';
import wechatImageText from '@/components/wechatImageText/index.vue';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
@Component({
  components: {
    // face,
    wechatImageText

  }
})
export default class faceLink extends Vue {
  @Prop({ default: true }) private dialogFormVisible!: boolean;
  @Prop({ default: true }) private editTypeId!: string;
  public diaTitle: String = '添加关键字';
  public links: string = '超文本链接实现方式：< a href="以https开头的链接地址">链接本文</ a>';
  public linkadress: string = '示例：< a href="https://baidu.com">点击此处去往百度</ a>';
  public form: any = {
    'rulename': '',
    'keyword': '',
    'region': 1
  };
  public regionname: string = '绝对匹配';
  public pageData: any = { content: '' };
  public title: String = "选择图文消息素材";
  public showvisible: Boolean = false;
  public saveType: Boolean = false;//判断是新增还是修改
  public msgType: string = 'text';
  public maxlength: Number = 8;
  public minlength: Number = 1;
  public text: string = '';
  public tableData: any[] = [];
  public delIds: any[] = [];//删除的关键字需要记录id  后台要求
  public keywordList: any[] = [];
  public setup: any[] = [{
    name: '绝对匹配',
    id: 1,
    select: true
  },
  {
    name: '模糊匹配',
    id: 2,
    select: false
  }];
  public selectshow: Boolean = false;
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0 toallShow
  public getDATA: any = {};//添加数据
  public rules: any = {
    rulename: [
      { required: true, message: '请输入规则名称', trigger: ['blur'] }],
    keyword: [
      { required: true, message: '请输入关键字', trigger: ['blur'] }],
    region: [
      { required: true, message: '请选择', trigger: ['change'] }],

  }
  public search: string = '';
  public loading: boolean = true;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public async created(): Promise<void> {
    var that = this, data_ = {};
    that.$set(that.form, 'region', 1)
    console.log(that.form.region)
    that.$forceUpdate();
    if (that.editTypeId) {
      data_ = {
        'id': that.editTypeId,
        'agentId': this.$route.query.id
      }
      that.getItem(data_)
      that.diaTitle = "编辑"
    } else {
      that.diaTitle = "添加关键字";
      that.loading = false;
    }
  }
  public cancalSure() {
    var that = this;
    this.$confirm('文件尚未保存, 是否继续关闭?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      that.cancal()
      that.setup = [];
    }).catch(() => {
    });
  }
  @Emit('selectChange')
  public cancal(): boolean {
    let isDone: boolean = false;
    return isDone
  }
  public chooseOrign(item: any) {
    console.log(item)
    var that = this;
    that.form.region = item.id;
    that.regionname = item.name;
    // selectshow
    for (var i = 0; i < that.setup.length; i++) {
      that.setup[i].select = false;
      if (that.setup[i].id == item.id) {
        that.setup[i].select = true;
        that.selectshow = false;
      }
    }
  }
  public regionnameClick() {
    var that = this;
    if (that.selectshow) {
      that.selectshow = false;
    } else {
      that.selectshow = true;
    }

  }

  // 点击确认
  public async btnOK(): Promise<void> {
    var that = this;
    if (that.form.rulename != '' && that.form.rulename != undefined && that.form.rulename != null) {
      if (that.keywordList.length > 0) {
        if (that.msgType == 'text') {
          if (that.text == '') {
            this.$message({
              message: '请输入文本消息',
              type: 'error'
            })
            return;
          } else {
            that.saveData()
          }
        } else if (that.msgType == 'news') {
          if (that.tableData.length <= 0) {
            that.$message({
              message: '请选择图文',
              type: 'error'
            });
            return;
          } else {
            that.saveData();
          }
        }
      } else {
        this.$message({
          message: '请添加关键字',
          type: 'error'
        })
      }
    } else {
      this.$message({
        message: '请输入规则名称',
        type: 'error'
      })
    }


  }
  // 切换文本。图文消息
  public async handleClick(tab: any, _event: any): Promise<void> {
    this.msgType = tab.name;
    if (this.msgType == 'news') {
      this.showvisible = true;
    }
  }
  //  
  // 编辑
  public async handleEdit(row: any): Promise<void> {
    console.log(row)
  }
  // 删除
  public async handleDelete(index: any, row: any): Promise<void> {
    var that = this;
    this.$confirm('此操作将永久删除该条, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      that.keywordList.splice(index, 1)
      if (row.id != '') {
        that.delIds.push(row.id)
      }
      this.$message({
        type: 'success',
        message: '删除成功,保存后生效!'
      });
    }).catch(() => {

    });

  }
  // 添加
  public async addKeyword(): Promise<void> {
    var that = this, data_ = {};
    const addRef = (that.$refs.form as any)
    addRef.validate(async (valid: any) => {
      if (valid) {
        data_ = {
          id: '',
          name: that.form.keyword,
          psrule: that.form.region//2 模糊匹配 1 绝对匹配  或者后台配置匹配方式数据
        }
        that.checkConkey(data_)
      }
    })
  }
  // 数据组装
  public async saveData(): Promise<void> {
    var that = this, data_ = {};
    if (that.msgType == 'text') {
      data_ = {
        'id': that.editTypeId,
        'msgType': 'text',
        'replyContent': this.text,
        'replyType': 'keyword',
        'agentId': that.$route.query.id,
        'keywords': that.keywordList,
        'delIds': that.delIds,
        'ruleName': that.form.rulename
      }
    } else if (that.msgType == 'news') {
      data_ = {
        'id': that.editTypeId,
        'msgType': 'news',
        'replyContent': that.tableData[0].id.toString(),
        'replyType': 'keyword',
        'agentId': that.$route.query.id,
        'keywords': that.keywordList,
        'delIds': that.delIds,
        'ruleName': that.form.rulename
      }
    }
    that.saveSend(data_)

  }
  // save 发送
  public async saveSend(data: Dict<any>): Promise<void> {
    var that = this;
    const res = await that.statisticsApi.saveReply(data);
    this.$message({
      message: '保存成功 ',
      type: 'success'
    })
    that.clear();
    that.cancal();
  }
  //清空
  public clear() {
    var that = this;
    // that.form.rulename = '';
    // that.form.keyword = '';
    // that.form.region = '';
    that.form = {
      'rulename': '',
      'keyword': '',
      'region': 1
    };
    that.keywordList = [];
    that.saveType = false;//判断是新增还是修改
    that.msgType = 'text';
    that.text = '';
    that.tableData = [];
    that.delIds = [];//删除的关键字需要记录id  后台要求
    that.getDATA = [];
  }
  // 根据id获取query信息
  public async getItem(data: Dict<any>): Promise<void> {
    var that = this;
    const res = await that.statisticsApi.getsingleReplyPost(data);
    if (res) {
      that.keywordList = res.keywords;
      that.form.rulename = res.ruleName;
      that.getDATA = res;
      if (res.msgType == 'text') {
        that.loading = false;
        this.msgType = 'text';
        that.text = that.getDATA.replyContent;
      } else if (res.msgType == 'news') {
        that.loading = false;
        this.msgType = 'news';
        // that.tableData = that.getDATA.replyContent;
        // // 通过id获取图文内容
        that.newList(that.getDATA.replyContent)
      }
    }
  }
  // 通过id获取图文内容
  public async newList(id: any) {
    let that = this;
    that.tableData = [];
    const res = await this.wechatImageTextService.articleDetail(id);
    console.log('res', res !== undefined)
    if (res !== undefined) {
      that.tableData.push(res)
      that.tableData[0].agentName = res.channelName;
      that.ishowImage = true
    } else {
      this.$message({
        type: 'error',
        message: '该规则下图文已被删除，请重新选择'
      })
      that.tableData = [];
      //  that.ishowImage = false;
    }

  }
  // 检查keyword
  public async checkConkey(data: any): Promise<void> {
    var that = this;
    var sendData =
    {
      name: data.name,
      psrule: data.psrule,
      agentId: this.$route.query.id,
    }
    const res = await this.statisticsApi.checkKeyword(sendData);
    if (res) {
      this.$message({
        message: '当前应用/专栏专栏下已有此关键字信息！',
        type: "error"
      })
    } else {
      // 添加到里面
      that.keywordList.unshift(data);
      that.form.keyword = '';
    }
  }
  // 监听input输入
  public inputChange(row: any) {
    if (row.row.name.length < 1) {
      this.$message({
        message: '请至少输入一个字',
        type: 'warning'
      })
    } else {
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      console.log(re.test(row.row.name))
      if (re.test(row.row.name)) {
        this.$message({
          message: '第一个字符请不要输入空格！',
          type: 'warning'
        })
      }
    }
  }

  //二次触发点击事件
  public ishow() {
    let child = (this.$refs.childImage as wechatImageText)
    child.checkCont()
    child.types = '1'
  }
  //得到子组件传过来的值 
  public async getChild(val: any): Promise<void> {
    this.tableData = [];
    this.tableData.push(val);
    this.ishowImage = true
  }
  // 监听规则名称input
  public inputChangeListen(val: any) {
    console.log(val)
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    console.log(re.test(val))
    if (re.test(val)) {
      this.form.rulename = ''
    }
  }
  public ikeywordChangeListen(val: any) {
    console.log(val)
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    console.log(re.test(val))
    if (re.test(val)) {
      this.form.keyword = ''
    }
  }
  public newChangeListen(val: any) {
    console.log(val)
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    console.log(re.test(val))
    if (re.test(val)) {
      this.text = ''
    }
  }

}
