




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
// import face from '@/components/face/face.vue';
import wechatImageText from '@/components/wechatImageText/index.vue';
import focusReply from '@/components/replyTab/focusReply.vue';
import defaultReply from '@/components/replyTab/defaultReply.vue';
import keywordReply from '@/components/replyTab/keywordReply.vue';
import createKeyword from '@/views/wechatReply/createKeyword.vue';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import { takeRight } from 'lodash';
@Component({
  components: {
    wechatImageText,
    // face,
    createKeyword,
    focusReply,
    defaultReply,
    keywordReply
  }
})
export default class wechatMenu extends Vue {
  public activeFocus: String = 'text' //关注回复
  public activeDefault: String = 'text';//默认回复
  public activeClass: String = 'defaul'; //tab显示哪一个 1 关注  2 默认  3 关键字
  public dialogFormVisible: boolean = false;//是否显示弹框
  public dataJson: any = {};//是否显示弹框
  // i8n
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(statisticsApi) private statisticsApi!: statisticsApi;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public created(): void {
    console.log('2', this.$route.query.id);
    this.dataJson.acceptChannelId = this.$route.query.id;

    // follow :关注回复  ：follow 关注回复 ，defaul 默认回复，keyword 关键字回复
  }
  // 关注 默认 关键词 切换 1 关注  2 默认  3 关键字
  public getItem(index: any): void {
    var that = this;
    console.log('index', index)
    that.activeClass = index;  // 把当前点击元素的index，赋值给activeClass
  }
  //创建
  public createKey(): void {
    console.log(this.dialogFormVisible)
    this.dialogFormVisible = true;
  }
  public getcancel(e: any): void {
    this.dialogFormVisible = e
  }
  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1)
  }
  // parentVal 父组件调用子组件方法
  // public async invokenCildMethod() {
  //   this.$refs.child.parentVal('123')
  // }
  /**
   * -END- CALL SERVICE
   */
}
