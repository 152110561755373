













































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import wechatImageText from '@/components/wechatImageText/index.vue';
import createKeyword from '@/views/wechatReply/createKeyword.vue';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {
        wechatImageText,
        createKeyword
    }
})
export default class wechatMenu extends Vue {
    public dialogFormVisible: boolean = false;//是否显示弹框
    public creatShow: boolean = false;//是否显示弹框
    public editTypeId: string = '';//是否编辑 携带编辑id
    public form: any = {}
    public tableData: any[] = [];//关键字列表
    public loading: boolean = true;
    // i8n
    @Inject(I18nService) private i18nSvc!: I18nService;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    get i18n(): Dict<any> {
        return this.i18nSvc.i18nData;
    }
    public created(): void {
        // follow :关注回复  ：follow 关注回复 ，defaul 默认回复，keyword 关键字回复
        this.getQuery()
    }

    //创建
    public createKey(row: any): void {
        var that = this;
        that.creatShow = true;
        console.log('修改', row)
        if (row) {
            // 修改
            that.editTypeId = row.id;
            localStorage.setItem('editTypeId', row.id)
        } else {
            // 添加 editType
            that.editTypeId = '';
        }
        this.dialogFormVisible = true;
    }
    // 删除
    public async deleteList(row: any): Promise<void> {
        var that = this;
        let param = {
            id: row.id
        }
        try {
            await that.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            await that.statisticsApi.deleReply(param);
            that.getQuery()
            that.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {

        }
    }

    public getcancel(e: any): void {
        var that = this;
        console.log('e', e)
        that.creatShow = false;
        that.dialogFormVisible = e;
        that.getQuery();
    }
    //返回
    public async handleGoBack(): Promise<void> {
        this.$router.go(-1)
    }

    // 获取内容 query  接口一样 type更换
    public async getQuery(): Promise<void> {
        var that = this;
        var sendData =
        {
            agentId: this.$route.query.id
        }
        let id = (this.$route.query.id) as any;
        const res = await this.statisticsApi.getkeywordList(id);
        that.loading = false;
        if (res) {
            that.tableData = res;
        } else {
            that.tableData = [];
        }
    }
    /**
     * -END- CALL SERVICE
     */
}
