




















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
//引入组件
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import wechatImageText from '@/components/wechatImageText/index.vue';
import wechatImage from '@/components/wechatImageText/wechatImageText.vue';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {
        wechatImageText,
        wechatImage,
    }
})
export default class creatMessage extends Vue {
    @Prop({ default: true }) private dataJson: any;
    public showvisible: Boolean = false;
    public form: any = {
        pages: 1,
        size: 5,
        current: 1
    };
    public links:string='超文本链接实现方式：< a href="以https开头的链接地址">链接本文</ a>';
    public linkadress:string='示例：< a href="https://baidu.com">点击此处去往百度</ a>';
    public pageData: any = { replyContent: '' };
    public msgType: string = 'text';
    public text: string = '';
    public title: String = "选择图文消息素材";
    public tableData: any[] = [];
    public tableDataId: string = '';
    public applications: any[] = [];
    public ishowImage: Boolean = false;//默认是没有选中数据tableData==0 toallShow
    public saveType: Boolean = true;//默认是没有数据的状态 即新增
    public btndisabled: Boolean = false;//默认是没有数据的状态 即新增
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    @Inject(I18nService) private i18nSvc!: I18nService;
    get i18n(): Dict<any> {
        return this.i18nSvc.i18nData;
    }
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        if (this.$route.query.id != '' && this.$route.query.id != undefined && this.$route.query.id != null) {
            this.applicationsList();
            this.getFormation();
        }
    }
    //得到子组件传过来的值 
    public async getChild(val: any): Promise<void> {
        this.tableData = [];
        this.tableData.push(val);
        this.ishowImage = true
    }
    //二次触发点击事件
    public ishow() {
        let child = (this.$refs.childImage as wechatImageText)
        console.log('110', child)
        child.checkCont()
        child.types = '1';
    }

    //返回
    public async handleGoBack(): Promise<void> {
        this.$router.go(-1)
    }
    // 切换文本。图文消息
    public async handleClick(tab: any, event: any): Promise<void> {
        this.msgType = tab.name;
        if (this.msgType == 'news') {
            this.showvisible = true;
        }
    }
    // 父组件传进来的值
    public async parentVal(val: any): Promise<void> {
        console.log(val)
    }
    //获取专栏
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res;
        if (this.applications.length > 0) {
            for (var i = 0; i < this.applications.length; i++) {
                if (this.applications[i].channelAgentId == this.$route.query.id) {
                    localStorage.setItem('acceptChannelId', this.applications[i].id)
                }
            }
        }
    }
    // 查询专栏下该tab信息
    public async getFormation(): Promise<void> {
        var that = this;
        var data_ = {
            'replyType': 'defaul',
            'agentId': this.$route.query.id
        };
        const res = await that.statisticsApi.getAllReply(data_);
        if (res != '') {
            that.saveType = false;
            that.pageData = res[0];
            if (res[0].msgType == 'text') {
                this.msgType = 'text';

                that.text = that.pageData.replyContent;
            } else if (res[0].msgType == 'news') {
                this.msgType = 'news';
                if (that.pageData.replyContent != '') {
                    that.tableDataId = that.pageData.replyContent;
                    // 通过id获取图文内容
                    that.newList(that.tableDataId)
                } else {

                }
            }
        } else {
            that.saveType = true;
        }
    }
    // 数据核实  msgType
    public async checkData(): Promise<void> {
        var that = this;
        that.saveData();
        // 0906 专栏的关注回复/默认回复可以为空保存
        return;
        if (that.msgType == 'text') {
            if (that.text == '') {
                that.$message({
                    message: '请输入文字信息',
                    type: 'error'
                });
                return;
            } else {
                that.saveData();
            }
        } else if (that.msgType == 'news') {
            // tableData.length
            if (that.tableData.length <= 0) {
                that.$message({
                    message: '请选择图文',
                    type: 'error'
                });
                return;
            } else {
                that.saveData();
            }
        }
    }
    // 数据组装
    public async saveData(): Promise<void> {
        var that = this, data_ = {};
        if (that.saveType) {
            // 新增
            if (that.msgType == 'text') {
                that.tableData = [];
                that.ishowImage = false;
                data_ = {
                    'id': '',
                    'msgType': 'text',
                    'replyContent': that.text,
                    'replyType': 'defaul',
                    'agentId': this.$route.query.id
                }
            } else if (that.msgType == 'news') {
                that.text = '';
                if (that.tableData.length > 0) {
                    data_ = {
                        'id': '',
                        'msgType': 'news',
                        'replyContent': that.tableData[0].id.toString(),
                        'replyType': 'defaul',
                        'agentId': this.$route.query.id
                    }
                } else {
                    data_ = {
                        'id': '',
                        'msgType': 'news',
                        'replyContent': '',
                        'replyType': 'defaul',
                        'agentId': this.$route.query.id
                    }
                }
            }

        } else {
            // 修改
            console.log('that.pageData', that.pageData)
            if (this.msgType == 'text') {
                that.tableData = [];
                that.ishowImage = false;
                data_ = {
                    'id': that.pageData.id,
                    'msgType': 'text',
                    'replyContent': that.text,
                    'replyType': 'defaul',
                    'agentId': this.$route.query.id
                }
            } else if (this.msgType == 'news') {
                that.text = '';
                if (that.tableData.length > 0) {
                    data_ = {
                        'id': that.pageData.id,
                        'msgType': 'news',
                        'replyContent': that.tableData[0].id.toString(),
                        'replyType': 'defaul',
                        'agentId': this.$route.query.id
                    }
                } else {
                    data_ = {
                        'id': that.pageData.id,
                        'msgType': 'news',
                        'replyContent': '',
                        'replyType': 'defaul',
                        'agentId': this.$route.query.id
                    }
                }

            }
        }
        that.saveSend(data_)
    }
    // save 发送
    public async saveSend(data: Dict<any>): Promise<void> {
        var that = this;
        that.btndisabled = true;
        const res = await that.statisticsApi.saveReply(data);
        console.log('res', res)
        this.$message({
            message: '保存成功 ',
            type: 'success'
        })
        that.getFormation();
        that.btndisabled = false;
    }
    // 通过id获取图文内容
    public async newList(id: any) {
        let that = this;
        that.tableData = [];
        const res = await this.wechatImageTextService.articleDetail(id);
        that.tableData.push(res)
        that.ishowImage = true
    }
    // 监听input
    public inputChange(val: any) {
        console.log(val)
        var regu = "^[ ]+$";
        var re = new RegExp(regu);
        console.log(re.test(val))
        if (re.test(val)) {
            this.text = ''
        }
    }
}


